import { Conditional, Flex, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { jobSteps } from '../../../../../../../../../constants/options';
import { DuplicatedJobsSection } from '../../../../../common/duplicated-jobs-section';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';

export const JobFailedContent = () => {
  const job = useRecoilValue(completePrintHarvestModalAtoms.job);
  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);
  const duplicatedJobs = useRecoilValue(
    completePrintHarvestModalAtoms.duplicatedJobs,
  );

  const shouldDiscardPreppedPacket = checkAgainstFailingChecklistItems(
    checklist,
    (item) => {
      return item.failStatusShouldDiscardPreppedPacket;
    },
  );

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        subTitleText="Please place the failed print in the appropriate bin."
        titleText={`${job.step} ${job.type} job failed`}
      />

      <Stack gap="x">
        <Conditional
          condition={shouldDiscardPreppedPacket}
          Fallback={
            <>
              <Flex alignItems="center" gap="x">
                <Icon color="t2" name="work:sd_card_outline" />
                <Typography text="Re-use prepped packet" variant="h5" />
              </Flex>
              <Typography text="Make sure you gather the prepped packet (SD card and RFID tag) and re-start the print on an available machine." />
            </>
          }
        >
          <>
            <Flex alignItems="center" gap="x">
              <Icon color="t2" name="work:sd_card_filled_exclamation" />
              <Typography text="Discard prepped packet" variant="h5" />
            </Flex>
            <Typography
              text={`${jobSteps.print} job will need to be transferred again. Please ensure SD card is recycled appropriately.`}
            />
          </>
        </Conditional>
      </Stack>

      <JobFailReasonsList checklist={checklist} />

      <DuplicatedJobsSection duplicatedJobs={duplicatedJobs} showTitle />
    </Stack>
  );
};
