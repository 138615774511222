import { useState } from 'react';
import { SearchField, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useSearch } from '../../../hooks';
import { productsPageAtoms } from './products-filter.atoms';
import {
  productsFilterCategoriesOptions,
  productsFiltersStatusLabels,
} from './products-filter.constants';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { Inventory } from '../../../api/inventories/routes/fetch-all/fetch-all.types';
import { inventoryTypes } from '../../../constants/options';
import {
  paintInventoryNamePattern,
  useFetchAllInventoriesQuery,
} from '../../../api/inventories/routes/fetch-all';

const ProductsFilter = () => {
  const [inventorySearch, setInventorySearch] = useState<string>('');
  const [inventoryFilter, setInventoryFilter] = useRecoilState(
    productsPageAtoms.filters.inventory,
  );

  const { onChangeSearch } = useSearch({
    onSearchCallback: setInventorySearch,
  });

  const onSelectInventory = (option: Inventory) => {
    setInventoryFilter(option?.id);
  };

  const { inventories, isLoading } = useFetchAllInventoriesQuery({
    transform: (response) => {
      const updatedInventories: Inventory[] =
        response?.inventories
          ?.filter((inventory) => {
            return (
              inventory.name.toLowerCase().includes(inventorySearch) ||
              inventory.id.toString().toLowerCase().includes(inventorySearch)
            );
          })
          ?.map((inventory) => {
            // ! Disable paint inventories as specific paints are no longer applied at the product level - Decker - July 19, 2024
            const isPaint =
              inventory.type === inventoryTypes.material &&
              paintInventoryNamePattern.test(inventory.name);

            return {
              ...inventory,
              disabled: isPaint,
            };
          }) || [];

      return updatedInventories;
    },
    transformDependencies: [inventorySearch],
  });

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={productsPageAtoms.filters.statuses}
          default={productsPageAtoms.defaults.filters.statuses}
          text="Type"
        />
        <AtomsCheckboxList
          atom={productsPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={[...productsFiltersStatusLabels]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={productsPageAtoms.filters.categories}
          default={productsPageAtoms.defaults.filters.categories}
          text="Category"
        />
        <AtomsCheckboxList
          atom={productsPageAtoms.filters.categories}
          gridProps={{ columns: 2 }}
          items={productsFilterCategoriesOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={productsPageAtoms.filters.inventory}
          default={productsPageAtoms.defaults.filters.inventory}
          text="Inventory used"
        />
        <SearchField
          autoSelectFirst={false}
          idProperty="id"
          items={inventories}
          labelProperty="name"
          maxHeight="40rem"
          placeholder="Select Inventory..."
          processing={isLoading}
          renderItem={(option) => {
            const textColor = option.disabled ? 't2' : 't1';

            return (
              <Typography
                color={textColor}
                horizontalPadding="1.6rem"
                text={`${option.name} - #${option.id}`}
                verticalPadding=".8rem"
              />
            );
          }}
          value={inventoryFilter}
          onSelect={onSelectInventory}
          onTextChange={onChangeSearch}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default ProductsFilter;
